// Todo: we should be able to use variables for the css colors.

.context-menu {
    position: fixed;
    border: 1px solid black;
    z-index: 999;
    opacity: .9;
}

.card-menu {
  box-shadow:0 20px 50px #333;

  ul {
    margin:0;
    padding:0;
    list-style:none;

    li {
      width:100%;
      display:block;
      position:relative;

      button {
        cursor: pointer;
        border: 1px solid transparent;
        text-align: inherit;
        width:100%;
        padding:14px 22px;
        color:#f0f0f0 !important;
        background:#414956;
        border-bottom:solid 1px #3b424d;
      }
    }
    li:hover {
      button{
        background:#3b424d
      }
    }
  }
}
