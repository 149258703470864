region.wavesurfer-region {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  z-index: 5 !important;
  background-color: hsla(27, 89%, 48%, 0.4);
}

region.wavesurfer-region:after {
  text-align: center;
  content: attr(data-region-label);
  font-weight: 700;
  font-size: 10pt;
  z-index:5;
}

region.wavesurfer-region[data-region-highlight] {
border: 1px solid rgb(86, 180, 239);
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
}

.wavesurfer-region[data-region-name="agentAction"]{
  width: 0.5em !important;
  background-color: hsla(27, 89%, 48%, 0) !important;
  border-left: thin dashed #bababa;
}

.wavesurfer-region[data-region-name="agentAction"]:after{
  margin-left: -0.5em;
  border: thin dotted #03A9F4;
  background-color: hsla(184, 90%, 49%, 0.4);
}



.wavesurfer-region[data-region-name="agentAction"][data-region-actionType="mouse"]:after{
  background-color: hsla(17, 90%, 49%, 0.4);
}


#wave-minimap overview {
  background-color: #03A9F4;
  opacity: 0.1;
}

.playBtn {
  border: 0;
  background-color: transparent;
  margin: auto auto;
  width: 4rem;
  height: 2.5rem;
  color: #646777;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 1s;
  padding: 0;
}
.playBtn:hover{
  opacity: 1;
  transition: opacity 1s;
  transition-timing-function: ease;
}