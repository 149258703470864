.container {
  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }

  @media screen and (min-width: 1880px) {
    max-width: 1785px;
  }
}


.back__link {
  &:hover {
    color: $color-accent;
  }
}
.back__link-title {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
}

.back__link-icon {
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;

  @include themify($themes) {
    color: themed('colorLinkIcon');
  }
}

.back__link-icon {
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;

  @include themify($themes) {
    color: themed('colorLinkIcon');
  }
}


.container__full {
  padding-left: 0;
  min-height: 100vh;
  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }
  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }

  @media screen and (min-width: 1631px) {
    width: 100%;
    max-width: 2040px;
  }

  .container {
    padding-top: 5rem;
    padding-bottom: 2rem;
  }
}

.container__wrap {
  padding-left: 0;
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 576px) {
    padding-left: 250px;
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}