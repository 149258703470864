/**/
/**/
@keyframes clockwise {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes anticlockwise {
	0% {
		-webkit-transform: rotate(360deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
	}
}
@keyframes clockwiseError {
	0% {
		-webkit-transform: rotate(0deg);
	}
	20% {
		-webkit-transform: rotate(30deg);
	}
	40% {
		-webkit-transform: rotate(25deg);
	}
	60% {
		-webkit-transform: rotate(30deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
	}
}
@keyframes anticlockwiseErrorStop {
	0% {
		-webkit-transform: rotate(0deg);
	}
	20% {
		-webkit-transform: rotate(-30deg);
	}
	60% {
		-webkit-transform: rotate(-30deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
	}
}
@keyframes anticlockwiseError {
	0% {
		-webkit-transform: rotate(0deg);
	}
	20% {
		-webkit-transform: rotate(-30deg);
	}
	40% {
		-webkit-transform: rotate(-25deg);
	}
	60% {
		-webkit-transform: rotate(-30deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
	}
}

h1 {
	margin: 200px auto 0 auto;
	color: black;
	font-family: 'Encode Sans Semi Condensed', Verdana, sans-serif;
	font-size: 10rem;
	line-height: 10rem;
	font-weight: 200;
	text-align: center;
	-webkit-transition: opacity 0.5s linear, margin-top 0.5s linear;
	transition: opacity 0.5s linear, margin-top 0.5s linear;
}
h2 {
	margin: 20px auto 30px auto;
	font-family: 'Encode Sans Semi Condensed', Verdana, sans-serif;
	font-size: 1.5rem;
	font-weight: 200;
	text-align: center;
	-webkit-transition: opacity 0.5s linear, margin-top 0.5s linear;
	transition: opacity 0.5s linear, margin-top 0.5s linear;
}
.generic_pages {
	.gear.one {
		animation: clockwise 3s linear infinite;
	}
	.gear.three {
		animation: clockwise 3s linear infinite;
	}
	.gear.two {
		animation: anticlockwise 3s linear infinite;
	}


	background-color: #fff;
  position: relative;
  margin: 0 auto;
  height: 100vh;
}
.gears {
	position: relative;
	margin: 0 auto;
	width: auto;
	height: 0;
}
.gear {
	position: relative;
	z-index: 0;
	width: 120px;
	height: 120px;
	margin: 0 auto;
	border-radius: 50%;
	background: black;
	&:before {
		position: absolute;
		left: 5px;
		top: 5px;
		right: 5px;
		bottom: 5px;
		z-index: 2;
		content: "";
		border-radius: 50%;
		background: #eaeaea;
	}
	&:after {
		position: absolute;
		left: 25px;
		top: 25px;
		z-index: 3;
		content: "";
		width: 70px;
		height: 70px;
		border-radius: 50%;
		border: 5px solid black;
		box-sizing: border-box;
		background: #eaeaea;
	}
	.bar {
		position: absolute;
		left: -15px;
		top: 50%;
		z-index: 0;
		width: 150px;
		height: 30px;
		margin-top: -15px;
		border-radius: 5px;
		background: black;
		&:before {
			position: absolute;
			left: 5px;
			top: 5px;
			right: 5px;
			bottom: 5px;
			z-index: 1;
			content: "";
			border-radius: 2px;
			background: #eaeaea;
		}
		&:nth-child(2) {
			transform: rotate(60deg);
			-webkit-transform: rotate(60deg);
		}
		&:nth-child(3) {
			transform: rotate(120deg);
			-webkit-transform: rotate(120deg);
		}
	}
}

.gear.one {
	left: -130px;
	animation: anticlockwiseErrorStop 2s linear infinite;
}
.gear.two {
	top: -75px;
	animation: anticlockwiseError 2s linear infinite;
}
.gear.three {
	top: -235px;
	left: 130px;
	animation: clockwiseError 2s linear infinite;
}
.back_btn {
	text-align: center;
	position: relative; 
	top: 20em
}
