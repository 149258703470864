.transition-enter {
  opacity: 0.01;
}

.transition-appear {
  opacity: 0.01;
}

.transition-appear.transition-appear-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.transition-enter.transition-enter-active {
  opacity: 1;
  transition: opacity 2000ms ease-in;
}

.transition-leave {
  opacity: 1;
}

.transition-leave.transition-leave-active {
  opacity: 0.01;
  transition: opacity 2000ms ease-in;
}
